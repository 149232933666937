import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchMedicalDetails = createAsyncThunk(
  "users/fetchMedicalDetails",
  async ({ token, doctorId, patientId }, thunkAPI) => {
    try {
      const response = await fetch(
        `https://preprod.omic.health/api/get-patient-medical-history/${doctorId}/${patientId}/`,
        {
          method: "GET",
          credentials: "include",

          headers: {
            Accept: "application/json",
            Authorization: `JWT ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return { ...data, patientId };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const medialInformationSlice = createSlice({
  name: "medicalInformationDetails",
  initialState: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
    medicalDetails: [],
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      return state;
    },
  },
  extraReducers: {
    [fetchMedicalDetails.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.medicalDetails = [
        ...state.medicalDetails,
        { patientId: payload.patientId, document: payload },
      ];
      return state;
    },
    [fetchMedicalDetails.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.message;
    },
    [fetchMedicalDetails.pending]: (state) => {
      state.isFetching = true;
    },
  },
});

export const { clearState } = medialInformationSlice.actions;

export const medicalInformationSelector = (state) =>
  state.medicalInformationDetails;
