import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ABORTED, abortController, errorCustomizer } from '../../../../utils/fetchController'
let requestCouter = 0;

// const counter = (n) => (console.log(requestCouter, n), requestCouter = Math.max(0, requestCouter + n), console.log(requestCouter), requestCouter)
const counter = (n) => (requestCouter = Math.max(0, requestCouter + n), requestCouter)

export const getInvoiceDetails = createAsyncThunk(
  "users/getInvoiceDetails",
  async ({ token, locationId, from_date, to_date }, thunkAPI) => {
    counter(1);
    const signal = abortController('getInvoiceDetails');
    try {
      const response = await fetch(
        `https://preprod.omic.health/api/data-insights/hospital-location/invoice/get-invoice-data/${locationId}/`,
        {
          method: "POST",
          credentials: "include",

          headers: {
            Accept: "application/json",
            Authorization: `JWT ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            from_date: from_date,
            to_date: to_date,
          }),
          signal
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return errorCustomizer(thunkAPI, e);
    }
  }
);

export const getInvoiceCategory = createAsyncThunk(
  "users/getInvoiceCategory",
  async ({ token, locationId, from_date, to_date }, thunkAPI) => {
    counter(1);
    const signal = abortController('getInvoiceCategory');
    try {
      const response = await fetch(
        `https://preprod.omic.health/api/get-billing-category-list/${locationId}/`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            Accept: "application/json",
            Authorization: `JWT ${token}`,
            "Content-Type": "application/json",
          },
          signal
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return errorCustomizer(thunkAPI, e);
    }
  }
);

export const getCateegoryData = createAsyncThunk(
  "users/getAdmissionData",
  async ({ token, locationId, from_date, to_date, category }, thunkAPI) => {
    counter(1);
    const signal = abortController('getCateegoryData');
    try {
      const response = await fetch(
        `https://preprod.omic.health/api/data-insights/hospital-location/invoice/get-invoice-category-datewise-data/${locationId}/`,
        {
          method: "POST",
          credentials: "include",

          headers: {
            Accept: "application/json",
            Authorization: `JWT ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            from_date: from_date,
            to_date: to_date,
            category: category,
          }),
          signal
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return errorCustomizer(thunkAPI, e);
    }
  }
);

export const getCateegoryDataUpdated = createAsyncThunk(
  "users/getCateegoryDataUpdated",
  async (
    { token, locationId, from_date, to_date, category, type, uid, cardAdded },
    thunkAPI
  ) => {
    counter(1);
    const signal = abortController('getCateegoryDataUpdated');
    try {
      const response = await fetch(
        `https://preprod.omic.health/api/data-insights/hospital-location/invoice/get-invoice-category-datewise-data/${locationId}/`,
        {
          method: "POST",
          credentials: "include",

          headers: {
            Accept: "application/json",
            Authorization: `JWT ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            from_date: from_date,
            to_date: to_date,
            category: category,
          }),
          signal
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return {
          value: category,
          type: type,
          data: data.chart_datewise_data,
          uid: uid,
          cardAdded: cardAdded,
        };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return errorCustomizer(thunkAPI, e);
    }
  }
);

export const saveUserDashboardData = createAsyncThunk(
  "users/saveUserDashboardData",
  async ({ token, data_insights_json }, thunkAPI) => {
    counter(1);
    const signal = abortController('saveUserDashboardData');
    try {
      const response = await fetch(
        `https://preprod.omic.health/api/data-insights/data-insights-dashboard-details/`,
        {
          method: "POST",
          credentials: "include",

          headers: {
            Accept: "application/json",
            Authorization: `JWT ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            data_insights_json: data_insights_json,
          }),
          signal
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return errorCustomizer(thunkAPI, e);
    }
  }
);

export const getUserDashboardData = createAsyncThunk(
  "users/getUserDashboardData",
  async ({ token, data_insights_json }, thunkAPI) => {
    counter(1);
    const signal = abortController('getUserDashboardData');
    try {
      const response = await fetch(
        `https://preprod.omic.health/api/data-insights/data-insights-dashboard-details/`,
        {
          method: "GET",
          credentials: "include",

          headers: {
            Accept: "application/json",
            Authorization: `JWT ${token}`,
            "Content-Type": "application/json",
          },
          signal
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return errorCustomizer(thunkAPI, e);
    }
  }
);

export const setCatDeletedData = createAsyncThunk(
  "users/setCatDeletedData",
  async (data) => {
    return data;
  }
);

export const invoiceInformationSlice = createSlice({
  name: "invoiceInformationDetails",
  initialState: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
    reportsDetails: [],
    averageStay: "",
    invoiceData: [],
    categories: [],
    catData: [],
    catDataUpdated: [],

    UserDashboardData: [],
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      return state;
    },
  },
  extraReducers: {
    [setCatDeletedData.fulfilled]: (state, { payload }) => {
      state.catDataUpdated = payload.data;
    },
    [getInvoiceDetails.fulfilled]: (state, { payload }) => {
      counter(-1);
      state.isFetching = !!requestCouter;
      state.isSuccess = true;
      state.invoiceData = payload;
      return state;
    },
    [getInvoiceDetails.rejected]: (state, { payload }) => {
      counter(-1);
      state.isFetching = payload === ABORTED ? true : !!requestCouter;
      state.isError = true;
      state.errorMessage = payload?.message;
    },

    [getInvoiceDetails.pending]: (state) => {
      state.isFetching = true;
    },
    [getInvoiceCategory.fulfilled]: (state, { payload }) => {
      counter(-1);
      state.isFetching = !!requestCouter;
      state.isSuccess = true;
      state.categories = payload?.category_list;
      return state;
    },
    [getInvoiceCategory.rejected]: (state, { payload }) => {
      counter(-1);
      state.isFetching = payload === ABORTED ? true : !!requestCouter;
      state.isError = true;
      state.errorMessage = payload?.message;
    },
    [getInvoiceCategory.pending]: (state) => {
      state.isFetching = true;
    },
    [getCateegoryData.fulfilled]: (state, { payload }) => {
      counter(-1);
      state.isFetching = !!requestCouter;
      state.isSuccess = true;
      state.catData = payload?.chart_datewise_data;
      return state;
    },
    [getCateegoryData.rejected]: (state, { payload }) => {
      counter(-1);
      state.isFetching = payload === ABORTED ? true : !!requestCouter;
      state.isError = true;
      state.errorMessage = payload?.message;
    },
    [getCateegoryData.pending]: (state) => {
      state.isFetching = true;
    },

    [getCateegoryDataUpdated.fulfilled]: (state, { payload }) => {
      counter(-1);
      state.isFetching = !!requestCouter;
      state.isSuccess = true;
      state.catDataUpdated = [...state.catDataUpdated, { ...payload }];
      return state;
    },
    [getCateegoryDataUpdated.rejected]: (state, { payload }) => {
      counter(-1);
      state.isFetching = payload === ABORTED ? true : !!requestCouter;
      state.isError = true;
      state.errorMessage = payload?.message;
    },
    [getCateegoryDataUpdated.pending]: (state) => {
      state.isFetching = true;
    },

    [getUserDashboardData.fulfilled]: (state, { payload }) => {
      counter(-1);
      state.isFetching = !!requestCouter;
      state.isSuccess = true;
      state.UserDashboardData = payload?.data_insights_json;
      return state;
    },
    [getUserDashboardData.rejected]: (state, { payload }) => {
      counter(-1);
      state.isFetching = payload === ABORTED ? true : !!requestCouter;
      state.isError = true;
      state.errorMessage = payload?.message;
    },
    [getUserDashboardData.pending]: (state) => {
      state.isFetching = true;
    },

    [saveUserDashboardData.fulfilled]: (state, { payload }) => {
      counter(-1);
      state.isFetching = !!requestCouter;
      state.isSuccess = true;
      return state;
    },
    [saveUserDashboardData.rejected]: (state, { payload }) => {
      counter(-1);
      state.isFetching = payload === ABORTED ? true : !!requestCouter;
      state.isError = true;
      state.errorMessage = payload?.message;
    },
    [saveUserDashboardData.pending]: (state) => {
      state.isFetching = true;
    },
  },
});

export const { clearState } = invoiceInformationSlice.actions;

export const invoiceInformationSelector = (state) =>
  state.invoiceInformationDetails;
