import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchPatientsDetails = createAsyncThunk(
  "users/fetchPatientsDetails",
  async ({ token, patientId }, thunkAPI) => {
    try {
      const response = await fetch(
        `https://preprod.omic.health/api/get-patient-profile/${patientId}/`,
        {
          method: "GET",
          credentials: "include",

          headers: {
            Accept: "application/json",
            Authorization: `JWT ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return { ...data };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const patientInformationSlice = createSlice({
  name: "patientInformationDetails",
  initialState: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
    patientsDetails: {},
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      return state;
    },
  },
  extraReducers: {
    [fetchPatientsDetails.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.patientsDetails = payload;
      return state;
    },
    [fetchPatientsDetails.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.message;
    },
    [fetchPatientsDetails.pending]: (state) => {
      state.isFetching = true;
    },
  },
});

export const { clearState } = patientInformationSlice.actions;

export const patientInformationSelector = (state) =>
  state.patientInformationDetails;
