/* eslint-disable no-self-assign */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const signupUser = createAsyncThunk(
  "users/signupUser",
  async ({ name, email, password }, thunkAPI) => {
    try {
      const response = await fetch(
        "https://mock-user-auth-server.herokuapp.com/https://preprod.omic.health/api/v1/users",
        {
          method: "POST",
          credentials: "include",

          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name,
            email,
            password,
          }),
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        localStorage.setItem("token", data.token);
        return { ...data, username: name, email: email };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const loginUser = createAsyncThunk(
  "users/login",
  async ({ username, password }, thunkAPI) => {
    try {
      const response = await fetch(
        "https://preprod.omic.health/user/omic-login/",
        {
          method: "POST",
          credentials: "include",

          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: username,
            password: password,
            is_patient: false,
          }),
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        localStorage.setItem("token", data.token);
        localStorage.setItem("doctorProfile", JSON.stringify(data.user));

        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const fetchOtp = createAsyncThunk(
  "users/fetchOtp",
  async ({ phone }, thunkAPI) => {
    try {
      const response = await fetch(
        "https://preprod.omic.health/user/omic-send-otp/",
        {
          method: "POST",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            phone_number: phone,
          }),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const fetchOtpByToken = createAsyncThunk(
  "users/fetchOtpByToken",
  async ({ phone, patient, token }, thunkAPI) => {
    try {
      const response = await fetch(
        `https://preprod.omic.health/api/v2/shared/authenticate-shared-link-generate-otp/${patient}/${token}/`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            link_password: phone,
          }),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const signWithOtp = createAsyncThunk(
  "users/signWithOtp",
  async ({ otpNumber }, thunkAPI) => {
    try {
      const response = await fetch(
        "https://preprod.omic.health/user/omic-otp-login/",
        {
          method: "POST",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            otp: otpNumber,
            is_patient: false,
          }),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        localStorage.setItem("token", data.token);
        localStorage.setItem("doctorProfile", JSON.stringify(data.user));
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const signWithOtpByToken = createAsyncThunk(
  "users/signWithOtpByToken",
  async ({ otpNumber, patientId, token }, thunkAPI) => {
    try {
      const response = await fetch(
        `https://preprod.omic.health/api/v2/shared/authenticate-shared-link-varify-otp/${patientId}/${token}/`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            otp: otpNumber,
            is_patient: false,
          }),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        localStorage.setItem("token", data.token);
        localStorage.setItem("doctorProfile", JSON.stringify(data.user));
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const setActiveTab = createAsyncThunk(
  "users/setActiveTab",
  async (activeKey) => {
    return activeKey;
  }
);

export const loginUserByToken = createAsyncThunk(
  "users/login",
  async ({ key, token }, thunkAPI) => {
    try {
      const response = await fetch(
        `https://preprod.omic.health/user/authenticate-user/logged-in/${key}/${token}/`,
        {
          method: "POST",
          credentials: "include",

          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: token,
            password: key,
            is_patient: false,
          }),
        }
      );
      let data = await response.json();
      console.log("Data --->", data);
      if (response.status === 200) {
        localStorage.setItem("token", data.token);
        localStorage.setItem("doctorProfile", JSON.stringify(data.user));

        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const doctorActivityTracking = createAsyncThunk(
  "users/doctorActivityTracking",
  async (
    { token, doctorProfileId, locationId, mainFeature, subFeature, context, uniqueId, specialty },
    thunkAPI
  ) => {
    try {
      const response = await fetch(
        `https://preprod.omic.health/api/doctor-activity-tracking/`,
        {
          method: "POST",
          credentials: "include",

          headers: {
            Accept: "application/json",
            Authorization: `JWT ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            doctor_profile_id: doctorProfileId,
            location_id: locationId,
            main_feature: mainFeature,
            sub_feature: subFeature,
            context: context,
            unique_id: uniqueId,
            specialty: specialty
          }),
        }
      );

      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const fetchUserBytoken = createAsyncThunk(
  "users/fetchUserByToken",
  async ({ token }, thunkAPI) => {
    try {
      const response = await fetch(
        "https://preprod.omic.health/user/get-user/",
        {
          method: "GET",
          credentials: "include",

          headers: {
            Accept: "application/json",
            Authorization: `JWT ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return { ...data };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState: {
    username: "",
    email: "",
    activeTabKey: "",
    isFetching: false,
    isSuccess: false,
    isOtpError: false,
    isOtpSuccess: false,
    isSearching: false,
    isError: false,
    errorMessage: "",
    userDetails: {},
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.isOtpSuccess = false;
      return state;
    },
  },
  extraReducers: {
    [signupUser.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.email = payload.user.email;
      state.username = payload.user.name;
    },
    [signupUser.pending]: (state) => {
      state.isFetching = true;
    },
    [signupUser.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },
    [setActiveTab.fulfilled]: (state, { payload }) => {
      state.activeTabKey = payload;
    },
    [loginUser.fulfilled]: (state, { payload }) => {
      state.email = payload.user.email;
      state.username = payload.user.first_name;
      state.isFetching = false;
      state.isSuccess = true;
      state.doctorDetails = payload.user;
      return state;
    },
    [loginUser.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.message;
    },
    [loginUser.pending]: (state) => {
      state.isFetching = true;
    },
    [loginUserByToken.fulfilled]: (state, { payload }) => {
      state.email = payload.user.email;
      state.username = payload.user.first_name;
      state.isFetching = false;
      state.isSuccess = true;
      state.doctorDetails = payload.user;
      return state;
    },
    [loginUserByToken.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = false;
      state.errorMessage = payload?.message;
    },
    [loginUserByToken.pending]: (state) => {
      state.isFetching = true;
    },
    [signWithOtp.fulfilled]: (state, { payload }) => {
      state.email = payload.user.email;
      state.username = payload.user.first_name;
      state.isFetching = false;
      state.isSuccess = true;
      state.doctorDetails = payload.user;
      return state;
    },
    [signWithOtp.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.message;
    },
    [signWithOtp.pending]: (state) => {
      state.isFetching = true;
    },
    [signWithOtpByToken.fulfilled]: (state, { payload }) => {
      state.email = payload.user.email;
      state.username = payload.user.first_name;
      state.isFetching = false;
      state.isSuccess = true;
      state.doctorDetails = payload.user;
      return state;
    },
    [signWithOtpByToken.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.message;
    },
    [signWithOtpByToken.pending]: (state) => {
      state.isFetching = true;
    },
    [fetchUserBytoken.pending]: (state) => {
      state.isFetching = true;
    },
    [fetchUserBytoken.fulfilled]: (state, { payload }) => {
      state.email = payload.user.email;
      state.username = payload.user.full_name;
      state.isFetching = false;
      state.isSuccess = true;
      state.userDetails = payload.user;
      state.doctorDetails = state.doctorDetails;
    },
    [fetchUserBytoken.rejected]: (state) => {
      state.isFetching = false;
      state.isError = true;
    },

    [fetchOtp.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isOtpError = true;
      state.errorMessage = payload.message;
    },
    [fetchOtp.pending]: (state) => {
      state.isFetching = true;
      state.isOtpError = false;
    },
    [fetchOtp.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isOtpSuccess = true;
      state.errorMessage = payload.message;
    },

    [fetchOtpByToken.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isOtpError = true;
      state.errorMessage = payload.message;
    },
    [fetchOtpByToken.pending]: (state) => {
      state.isFetching = true;
      state.isOtpError = false;
    },
    [fetchOtpByToken.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isOtpSuccess = true;
      state.errorMessage = payload.message;
    },
  },
});

export const { clearState } = userSlice.actions;

export const userSelector = (state) => state.user;
