import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ABORTED, abortController, errorCustomizer } from '../../../../utils/fetchController'

let requestCouter = 0;

const counter = (n) => (requestCouter = Math.max(0, requestCouter + n),requestCouter)

export const getAverageStay = createAsyncThunk(
  "users/getAverageStay",
  async ({ token, locationId, from_date, to_date }, thunkAPI) => {
    counter(1)
    const signal = abortController('getAverageStay');
    try {
      const response = await fetch(
        `https://preprod.omic.health/api/data-insights/hospital-location/admission/get-avg-length-of-stay/${locationId}/`,
        {
          method: "POST",
          credentials: "include",

          headers: {
            Accept: "application/json",
            Authorization: `JWT ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            from_date: from_date,
            to_date: to_date,
          }),
          signal
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return errorCustomizer(thunkAPI, e);
    }
  }
);

export const getAdmissionData = createAsyncThunk(
  "users/getAdmissionData",
  async ({ token, locationId, from_date, to_date }, thunkAPI) => {
    counter(1)
    const signal = abortController('getAdmissionData');
    try {
      const response = await fetch(
        `https://preprod.omic.health/api/data-insights/hospital-location/admission/get-admission-data/${locationId}/`,
        {
          method: "POST",
          credentials: "include",

          headers: {
            Accept: "application/json",
            Authorization: `JWT ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            from_date: from_date,
            to_date: to_date,
          }),
          signal
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return errorCustomizer(thunkAPI, e);
    }
  }
);

export const getReferralsData = createAsyncThunk(
  "users/getReferralsData",
  async ({ token, locationId, from_date, to_date, category }, thunkAPI) => {
    counter(1)
    const signal = abortController('getReferralsData');
    try {
      const response = await fetch(
        `https://preprod.omic.health/api/data-insights/hospital-location/referral/get-referral-data/${locationId}/`,
        {
          method: "POST",
          credentials: "include",

          headers: {
            Accept: "application/json",
            Authorization: `JWT ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            from_date: from_date,
            to_date: to_date,
          }),
          signal
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return errorCustomizer(thunkAPI, e);
    }
  }
);

export const getBedOccupancyData = createAsyncThunk(
  "users/getBedOccupancyData",
  async ({ token, locationId }, thunkAPI) => {
    counter(1)
    const signal = abortController('getBedOccupancyData');
    try {
      const response = await fetch(
        `https://preprod.omic.health/api/data-insights/hospital-location/admission/get-current-bed-occupancy/${locationId}/`,
        {
          method: "GET",
          credentials: "include",

          headers: {
            Accept: "application/json",
            Authorization: `JWT ${token}`,
            "Content-Type": "application/json",
          },
          signal
        }
      );

      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return errorCustomizer(thunkAPI, e);
    }
  }
);

export const getSurgeryList = createAsyncThunk(
  "users/getSurgeryList",
  async ({ token, locationId, from_date, to_date, query }, thunkAPI) => {
    counter(1)
    const signal = abortController('getSurgeryList');
    try {
      const response = await fetch(
        `https://preprod.omic.health/api/data-insights/hospital-location/surgery/get-surgery-search-results/${locationId}/`,
        {
          method: "POST",
          credentials: "include",

          headers: {
            Accept: "application/json",
            Authorization: `JWT ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            from_date: from_date,
            to_date: to_date,
            q: query,
          }),
          signal
        }
      );

      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return errorCustomizer(thunkAPI, e);
    }
  }
);

export const getSurgeryData = createAsyncThunk(
  "users/getSurgeryData",
  async (
    { token, locationId, from_date, to_date, surgeryList, filterText },
    thunkAPI
  ) => {
    counter(1)
    const signal = abortController('getSurgeryData');
    try {
      const response = await fetch(
        `https://preprod.omic.health/api/data-insights/hospital-location/surgery/get-surgery-data/${locationId}/`,
        {
          method: "POST",
          credentials: "include",

          headers: {
            Accept: "application/json",
            Authorization: `JWT ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            from_date: from_date,
            to_date: to_date,
            surgery_list: surgeryList ? [surgeryList] : [],
            custom_filter_text: filterText,
          }),
          signal
        }
      );

      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return errorCustomizer(thunkAPI, e);
    }
  }
);

export const admissionInformationSlice = createSlice({
  name: "admissionInformationDetails",
  initialState: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
    reportsDetails: [],
    averageStay: "",
    admissionData: [],
    referralData: [],
    surgeryData: [],
    surgeryDataList: [],
    bedOccupancy: 0,
    currentBedOccupancy: 0,
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      return state;
    },
  },
  extraReducers: {
    [getAverageStay.fulfilled]: (state, { payload }) => {
      counter(-1)
      state.isFetching = !!requestCouter;
      state.isSuccess = true;
      state.averageStay = payload.avg_length_of_stay;
      return state;
    },
    [getAverageStay.rejected]: (state, { payload }) => {
      counter(-1)
      state.isFetching = payload === ABORTED ? true : !!requestCouter;
      state.isError = true;
      state.errorMessage = payload?.message;
    },
    [getAverageStay.pending]: (state) => {
      state.isFetching = true;
    },
    [getSurgeryList.fulfilled]: (state, { payload }) => {
      counter(-1)
      state.isFetching = !!requestCouter;
      state.isSuccess = true;
      state.surgeryDataList = payload;
      return state;
    },
    [getSurgeryList.rejected]: (state, { payload }) => {
      counter(-1)
      state.isFetching = payload === ABORTED ? true : !!requestCouter;
      state.isError = true;
      state.errorMessage = payload?.message;
    },
    [getSurgeryList.pending]: (state) => {
      state.isFetching = true;
    },
    [getSurgeryData.fulfilled]: (state, { payload }) => {
      counter(-1)
      state.isFetching = !!requestCouter;
      state.isSuccess = true;
      state.surgeryData = payload;
      return state;
    },
    [getSurgeryData.rejected]: (state, { payload }) => {
      counter(-1)
      state.isFetching = payload === ABORTED ? true : !!requestCouter;
      state.isError = true;
      state.errorMessage = payload?.message;
    },
    [getSurgeryData.pending]: (state) => {
      state.isFetching = true;
    },
    [getAdmissionData.fulfilled]: (state, { payload }) => {
      counter(-1)
      state.isFetching = payload === ABORTED ? true : !!requestCouter;
      state.isSuccess = true;
      state.admissionData = payload;
      return state;
    },
    [getAdmissionData.rejected]: (state, { payload }) => {
      counter(-1)
      state.isFetching = payload === ABORTED ? true : !!requestCouter;
      state.isError = true;
      state.errorMessage = payload?.message;
    },
    [getAdmissionData.pending]: (state) => {
      state.isFetching = true;
    },
    [getReferralsData.fulfilled]: (state, { payload }) => {
      counter(-1)
      state.isFetching = !!requestCouter;
      state.isSuccess = true;
      state.referralData = payload;
      return state;
    },
    [getReferralsData.rejected]: (state, { payload }) => {
      counter(-1)
      state.isFetching = payload === ABORTED ? true : !!requestCouter;
      state.isError = true;
      state.errorMessage = payload?.message;
    },
    [getReferralsData.pending]: (state) => {
      state.isFetching = true;
    },
    [getBedOccupancyData.fulfilled]: (state, { payload }) => {
      counter(-1)
      state.isFetching = payload === ABORTED ? true : !!requestCouter;
      state.isSuccess = true;
      state.currentBedOccupancy = payload?.number_of_bed_occupancy_today;
      return state;
    },
    [getBedOccupancyData.rejected]: (state, { payload }) => {
      counter(-1)
      state.isFetching = payload === ABORTED ? true : !!requestCouter;
      state.isError = true;
      state.errorMessage = payload?.message;
    },
    [getBedOccupancyData.pending]: (state) => {
      state.isFetching = true;
    },
  },
});

export const { clearState } = admissionInformationSlice.actions;

export const admissionInformationSelector = (state) =>
  state.admissionInformationDetails;
